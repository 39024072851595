import React from 'react';

import '../styles/projectpreviewlong.scss'
import AniLink from "gatsby-plugin-transition-link/AniLink";


class ProjectPreviewLong extends React.Component {


    render() {
        return(
            <AniLink to={`/arbeiten/${this.props.urlName}`} className="my-4 full-width size-transform" paintDrip hex="#000" data-aos="fade-in" data-aos-duration="700">

                <div className="size-transform">

                    <div id="project-preview-container" className="relative w-auto">
                        {/* das folgende <div> wird für das preview-bild verwendet*/}
                        <div className="top-0 left-0 h-full w-full z-10 bg-cover" style={{ backgroundImage: `url("https://${this.props.bg_img.slice(2)}` }}></div>
                        {/* das folgende <div> wird für die inner-shadow überlagerung verwendet*/}
                        <div className="top-0 left-0 h-full w-full z-20 box-shadow-overlay absolute"></div>
                        
                        <div id="project-description-container" className="flex flex-col justify-end z-30 text-white p-6 top-0 left-0 w-full h-full absolute">
                            <div className="relative  mb-4 inline-block flex-shrink">
                                <h3 className="text-3xl lg:text-4xl inline-block animation-underline">{this.props.title}</h3>
                            </div>
                            <div id="project-details-container" className="flex flex-row justify-between lg:justify-start">
                                <div id="kunde" className="flex flex-col mr-6 lg:text-lg">
                                    <span className="font-medium">Kunde</span>
                                    <span className="font-light">{this.props.customer}</span>
                                </div>
                                <div id="kategorie" className="flex flex-col mx-6 lg:text-lg">
                                    <span className="font-medium">Kategorie</span>
                                    <span className="font-light">{this.props.category}</span>
                                </div>
                                <div id="jahr" className="flex flex-col ml-6 lg:text-lg">
                                    <span className="font-medium">Jahr</span>
                                    <span className="font-light">{this.props.year}</span>
                                </div>
                            </div>
                        </div>
                        
                    </div>

                </div>

            </AniLink>
        )
    }
}

export default ProjectPreviewLong;