import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import '../styles/arbeiten.scss'
import {graphql} from 'gatsby'

import FeaturedProjects from '../components/featuredProjects';
import ProjectPreviewLong from '../components/longProjectPreview'

const WorkPage = ({data}) => {

    return (
        <Layout>
            <SEO 
                title="Arbeiten"
                description="In enger Absprache mit unseren Kunden entwicklen und gestalten wir innovative Lösungen die begeistern" />
            <div className="main-wrapper"  data-aos="fade-in"  data-aos-duration="1000" data-aos-delay="400">
                <div className="headline-section flex flex-col justify-center h-48 md:h-64">
                    <h1 className="text-3xl md:text-4xl" data-aos="fade-in">Projekte, die <br /> begeistern</h1>
                </div>
                <div id="featured-section" className="flex flex-col justify-center mb-20">
                    <h2 className="text-3xl underline my-10" data-aos="fade-in">Vorgestellte Projekte</h2>
                    <FeaturedProjects />
                </div>
                <div id="all-projects-section" className="flex flex-col justify-center mb-20">
                    <h2 className="text-3xl underline mb-16" data-aos="fade-in">Alle Projekte</h2>
                    {data.allContentfulProjekt.nodes.map(
                        (projekt, key) => (
                            <ProjectPreviewLong
                            key={key}
                            urlName={projekt.projekttitel.replace(/\s/g, "_").toLowerCase()}
                            title={projekt.projekttitel}
                            customer={projekt.kunde}
                            category={
                                projekt.kategorie.map((kategorie, index) => ((index ? ', ' : '') + kategorie.kategorie))}
                            year={projekt.jahr}
                            bg_img={projekt.titelbild.file.url}
                            />
                        )
                    )}
                </div>
            </div>
        </Layout>
    )};

export const query = graphql`
  {
    allContentfulProjekt(sort: {fields: createdAt}) {
      nodes {
        id
        jahr
        createdAt
        einleitungstitel
        einleitungstext {
          einleitungstext
        }
        projekttitel
        kunde
        kategorie {
          kategorie
        }
        titelbild {
          file {
            url
          }
        }
      }
    }
  }
`



export default WorkPage;