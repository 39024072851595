import React from 'react';
import ProjectPreview from './projectPreview'
import { graphql, useStaticQuery } from 'gatsby'

const FeaturedProjects = () => {

    const data = useStaticQuery(graphql`
    {
      allContentfulProjekt(limit: 2, filter: {featured: {eq: true}}) {
        nodes {
          id
          jahr
          createdAt
          einleitungstitel
          einleitungstext {
            einleitungstext
          }
          projekttitel
          kunde
          kategorie {
            kategorie
          }
          titelbild {
            file {
              url
            }
          }
        }
      }
    }
  `)

    return (
        <div className="flex flex-col justify-center md:flex-row md:justify-between">
            {data.allContentfulProjekt.nodes.map(
                (projekt, key) => (
                    <ProjectPreview
                        key={key}
                        urlName={projekt.projekttitel.replace(/\s/g, "_").toLowerCase()}
                        title={projekt.projekttitel}
                        customer={projekt.kunde}
                        category={
                            projekt.kategorie.map((kategorie, index) => ((index ? ', ' : '') + kategorie.kategorie))}
                        year={projekt.jahr}
                        bg_img={projekt.titelbild.file.url}
                    />
                )
            )}
        </div>
    )
}

export default FeaturedProjects;